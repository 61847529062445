.str-chat-channel-list {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.str-chat__channel-header {
    border-top-right-radius: 7px;
}

/* .str-chat__channel-list__item--selected {
    background-color: #f0f0f0;
} */
.str-chat__edit-message-cancel {
    padding-right: 20px;
}

.mobile-channel-list {
    transition: .5s;

}

.str-chat {
    --str-chat__primary-color: #fafafa;
    --str-chat__active-primary-color: #FFFB00;
    --str-chat__surface-color: #e8e8e8;
    --str-chat__secondary-surface-color: #fafafa;
    --str-chat__primary-surface-color: #e3df04;
    --str-chat__primary-surface-color-low-emphasis: #FFFB00;
    --str-chat__border-radius-circle: 6px;
    --str-chat__avatar-background-color: #e2e1a4;
    --str-chat__message-bubble-color: #000000;
    --str-chat__card-attachment-color: #000000;
    --str-chat__channel-header-background-color: #e8e8e8;
    --str-chat__channel-preview-unread-badge-background-color: #FFFB00;
    --str-chat__channel-preview-hover-background-color: #e8e8e8;
    --str-chat__message-send-color: #FFFB00;
    --str-chat__message-send-disabled-color: #b1ae00;
    --str-chat__message-link-color: #3a3af1;
    --str-chat__edit-message-modal-send-button-color: #FFFB00;
    /* --str-chat__channel-preview-active-background-color: #ec92b2; */

}


.str-chat__theme-dark {
    --str-chat__channel-preview-active-background-color: #4d4d4d;
    --str-chat__channel-preview-hover-background-color: #4d4d4d;
    --str-chat__channel-header-background-color: #4d4d4d;
    --str-chat__secondary-surface-color:black;
    --str-chat__message-bubble-color: #e8e8e8;
    --str-chat__card-attachment-color: #e8e8e8;
    --str-chat__primary-surface-color: #8e8c00;
    .str-chat__quoted-message-preview {
        --str-chat__message-bubble-color: white;

    }
}

@media screen and (max-width: 800px) {
    .str-chat {
        --str-chat__channel-preview-active-background-color: #fff;
    }

    .str-chat__theme-dark {
        --str-chat__channel-preview-active-background-color: #17191c;
    }

    /* .str-chat__channel-preview-messenger--active
    {
        background-color: #fff;
    } */


    .mobile-channel-list.show {
        transform: translateZ(0);
    }

    .mobile-chat-view.hide {
        display: none;
    }

    .rightsidebar {
        display: none;
    }

    .mobile-channel-list {
        transform: translate3d(-100vw, 0, 0);
        height: 100vh;
        width: 100%;
        position: fixed;
        z-index: 9;

    }

    .str-chat__header-hamburger {
        display: block !important;
    }

    .mobile-chat-view {
        margin-top: 0px;
    }

    /* .str-chat-channel{
    position: fixed;
    width: 100%;
    height: 100vh;
} */
}