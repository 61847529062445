@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  scroll-behavior: smooth;
}
body, html, #root {
  min-height: 100lvh;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    /* background: #fafafa; */
    scroll-behavior: smooth;


}
#root{
  position: relative;
  /* min-height: 90svh; */
}
input,select{
  outline: none;
}
.batch_heart{
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.leftsidebar-active p {
  font-weight: bold;
}

.cloudinary-thumbnail.active{
  display:flex !important;
}
[data-test="carousel-inner-wrapper"]{
  margin-left:auto;
  margin-right:auto;
}
[data-test="upload-widget-popup"].statement{
  color:#fff;
}
/* .forum-post{
  background: white;
border-radius: 25px;
padding: 20px;
margin: 20px;
} */
.cloudinary-thumbnails{
  background: white;
  border-radius: 25px;
  margin: 10px;
  padding: 10px;
}
.cloudinary-delete{
  color:red;
}
.forum-post-bottom{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-top: 1px solid #e8e6e6;
  margin-top: 10px;
}
.repost-forum-post-top{
  border-bottom: 1px solid #e8e6e6;
}

.forum-post-top{
  align-items: center;
  margin-bottom:7px;
}
.comment-top{
  align-items: center;
  /* margin-bottom:7px; */
}

.serviceCardMediaContainer {
  [itemprop="image"], [itemprop="video"] {
    border-radius: 11px;
  }
}

@media (min-width: 800px) {
  [itemprop="image"], [itemprop="video"] {
    border-radius: 11px;
  }

}
@media (max-width: 800px) {
  .str-chat__modal--open .str-chat__modal__inner {
    width: 90% !important;
  }

}
.no-scroll {
  overflow: hidden;
}

.play-button::before {
  content: "";
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 40px solid white;
}


.paid-play-button {
  font-size: 100%;
  color: #fff;
  position: absolute;
  /* top: 27%; */
  top:2%;
  left: 2%;
  /* transform: translate(-50%, -50%); */
  z-index: 2;
}
.SocialProfile__NavLink.active {
  border-bottom: 2px solid #FFFB00;
  font-weight: bold;
}
.SocialProfile_FollowerButton__NavLink.active {
  background-color: white !important;
  border: 1px solid #FFFB00 !important;
}

.placeholder-bold::placeholder {
  font-weight: bold;
}

.dot-i {
  position: relative;
  display: inline-block;
}


.dot-i::after {
  content: '';
  position: absolute;
  top: -16px; 
  width: 16px; 
  height: 16px;
  background-color: yellow; 
  border-radius: 50%;
}
@media (max-width: 800px) {
  .dot-i::before,
  .dot-i::after {
      top: -8px;
      width: 8px; 
      height: 8px;
  }

}

.dot-i::after {
left: 0px; 
}

.outline-text {
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}